import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router)
import constantRouterMap from './routers'

const router = new Router({
  // mode: 'history',
  routes: constantRouterMap
});

export default router;