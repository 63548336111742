import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const getDefaultState = () => {
  return {
    loadingState:false,//是否已加载素材
    config:null,//配置
    userInfo:null,//用户信息
  }
}
const state = getDefaultState()

export default new Vuex.Store({
  state,
  mutations: {
    SET_LOADINGSTATE(state,bool){
      state.loadingState = bool
    },
    SET_CONFIG(state,obj){
      state.config = obj
    },
    SET_USERINFO(state,obj){
      state.userInfo = obj
    }
  },
  actions: {
    
  },
  modules: {
  }
})
