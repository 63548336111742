// import TabBar from '@/components/tabbar.vue';
const constantRouterMap = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home/index.vue')
    },
    {
        path: '/loading',
        name: 'Loading',
        component: () => import('@/views/Loading/index.vue')
    },
    {
        path: '/lottery',
        name: 'Lottery',
        component: () => import('@/views/Lottery/index.vue')
    },
    {
        path: '/record',
        name: 'Record',
        component: () => import('@/views/Record/index.vue')
    },
];
export default constantRouterMap;
