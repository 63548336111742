import router from './router'
import store from './store';

// 登录前白名单
const whiteList = ['/loading'];
// 登录后黑名单
const blackList = ['/loading'];
// 默认页面
const defaultPage = '/'

router.beforeEach((to, from, next) => {
    const { loadingState } = store.state
    if (loadingState) {
      if (blackList.indexOf(to.path) == -1) {
        if(store.state.userInfo || to.path==defaultPage){
          next()
        }else{
          next({ path: defaultPage ,replace:true})
        }
      } else {
        next({ path: defaultPage ,replace:true})
      }
    } else {
      if(whiteList.indexOf(to.path) !== -1) {
        next()
      } else {
        next({path:`/loading?redirect=${to.fullPath}`,replace:true})
      }
    }
});